.block-banner {
  .banner-text {
    font-weight: 300;
    @include font-size($font-size-base * 1.05);
    letter-spacing: $wide-letter-spacing;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
      letter-spacing: $narrow-letter-spacing;
    }
  }
}
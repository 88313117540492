.navbar-nav {
  .dropdown {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
}

.carousel-indicators {
  margin-bottom: -2rem !important;

  button {
    border-radius: 50%;
    width: 12px !important;
    height: 12px !important;
    margin-right: 6px !important;
    margin-left: 6px !important;

    &:not(.active) {
      background-color: $text-color;
    }
  }
}

.carousel-control-prev, .carousel-control-next {
  .carousel-control-prev-icon, .carousel-control-next-icon {
    background-size: 70% 70%;
    border-radius: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}


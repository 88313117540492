@import '../../_assets/styles/colors';

#argoconsilium-navigation {
  height: 100px;
  z-index: $zindex-popover;

  .google-maps-icon-wrapper {
    svg {
      height: 20px;
    }
  }

  .navbar-nav {
    @media (max-width: 1600px) {
      gap: 0 !important;
    }

    .dropdown-menu {
      margin-top: 0;
    }

    .nav-item {
      &:first-child {
        .nav-link {
          padding-left: 0;
        }
      }

      &:focus {
        box-shadow: none !important;
      }

      > .nav-link {
        padding-top: 0;
        padding-bottom: 0;
        color: $navbar-link-color;
        text-decoration: none;
        @include font-size($font-size-base * 0.9);
      }

      &.active, &:hover {
        > .nav-link {
          color: $primary-green;
        }
      }


      .dropdown-item {
        position: relative;

        > .nav-link {
          @include font-size($font-size-base * 0.8);
          padding-top: 0;
          padding-bottom: 0;
          color: $navbar-link-color;
          text-decoration: none;
        }

        &.active, &:focus, &:hover {
          background-color: transparent;

          > .nav-link {
            color: $primary-green;
            background-color: transparent;
          }
        }

        .submenu {
          display: none;
          position: absolute;
          top: -10px;
          left: 100%;
          min-width: 200px;

          li {
            &.active, :focus, :hover {
              color: $primary-green !important;
              background-color: transparent !important;
            }

            .dropdown-item {
              @include font-size($font-size-base * 0.8);

              &.active, :focus, :hover {
                color: $primary-green !important;
                background-color: transparent !important;
              }
            }

            :focus, :hover {
              .dropdown-item {
                color: $primary-green !important;
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }
  }

  #mobile-navbar-toggler {
    border: none;
    box-shadow: none;
  }

  #language-selector {
    @include font-size($font-size-base * 0.9);
    @include rfs($font-size-base * 1.2, line-height);

    a {
      @include font-size($font-size-base * 0.9);
      @include rfs($font-size-base * 1.2, line-height);
    }
  }

  .navbar-brand {
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media (max-width: 768px) {
      display: flex;
      transform: initial;
      left: initial;
      top: initial;
      position: relative;
      width: 80%;
    }

    img {
      max-width: 100%;
    }
  }

  .google-maps-pin {
    bottom: 2px;
    position: relative;
  }
}

#mobile-navbar-argoconsilium {
  height: 100%;
  width: 100%;
  z-index: $zindex-fixed;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: $white;
  overflow-x: hidden;
  transition: 0.5s;

  .google-maps-icon-wrapper {
    svg {
      height: 20px;
    }
  }

  .overlay-content {
    position: relative;
    top: 100px;
    width: 100%;
    margin-top: 2rem;

    .mobile-navbar-item {
      .nav-link {
        @include font-size($font-size-base * 1.8);
        color: $primary-green;
        font-weight: bold;
        font-family: $headings-font-family;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        list-style: none;
        padding-left: 1rem;
      }
    }

    .mobile-navbar-subitem {
      a {
        @include font-size($font-size-base * 1.1);
      }
    }

    .sub-navigation {
      a {
        @include font-size($font-size-base * 1.1);
        font-weight: $font-weight-light;
      }
    }

    #language-selector {
      color: $primary-green;
      padding-left: 0 !important;
      @include font-size($font-size-base * 1.1);

      .text-primary {
        color: $dark !important;
        font-weight: bold;
      }
    }
  }
}

#linked-in {
  &:hover, &.active {
    svg {
      path {
        fill: $primary-green !important;
      }
    }
  }
}

.icon-hover {
  &:hover, &.active {
    svg {
      path, circle {
        stroke: $primary-green !important;
      }
    }
  }
}


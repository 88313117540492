.hero {
  border-bottom: 6px solid $primary-green;

  .slogan-title {
    @include font-size($font-size-base * 1);
    letter-spacing: $narrow-letter-spacing;
    line-height: 1.2rem;

    @include media-breakpoint-up(sm) {
      letter-spacing: $wide-letter-spacing;
      @include font-size($font-size-base * 1.3);
      line-height: 1.8rem;
    }
  }

  .slogan-container {
    position: relative;
    width: 100%;
    min-height: 80px;

    .slogan-title {
      transition: opacity 1s ease-in-out;
      width: 100%;
      word-break: break-word;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .slogan {
    @include font-size($font-size-base * 1.2);
    color: $text-color;

    @include media-breakpoint-down(sm) {
      @include font-size($font-size-base * 1);
    }
  }
}
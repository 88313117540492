.image-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  .main-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0;
    }
  }
}

.employee {
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.hidden {
    opacity: 0;
    transform: scale(0.9);
    pointer-events: none;
    position: relative;
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
    position: relative;
  }
}


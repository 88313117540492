.block-news {
  .news-element-link {
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }
  }

  .news-element {
    .news-element-title {
      @include font-size($font-size-base * 1.05);
      letter-spacing: unset;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 5px;
        width: 100px;
        background-color: $primary-green;
        left: 0;
        bottom: -1rem;
      }
    }

    .news-element-text {
      @include font-size($font-size-base * 0.9);
      color: $text-color;
      font-family: $headings-font-family;
    }
  }
}
footer {
  h1, h2, h3, h4, h5, h6 {
    letter-spacing: $narrow-letter-spacing;
  }

  background-color: $footer-background-color;
  color: $footer-text-color;
  @include font-size($font-size-base * 0.8);

  a {
    text-decoration: none;
    @include font-size($font-size-base * 0.8);
  }

  .subfooter {
    border-top: 1px solid $footer-border-color;
    @include font-size($font-size-base * 0.7);
  }

  .social-icon {
    position: relative;
    top: -2px;

    path {
      fill: $primary-green !important;
    }
  }
}


// Utilities
$enable-negative-margins: true;

// Typography
$font-size-root: 20px;
$font-size-base: 1rem;
$font-weight-medium: 500;
$font-weight-bold: 600;


$font-family-sans-serif: "Segoe UI", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$small-font-size: .8em;
$headings-font-weight: bold;

$h1-font-size: $font-size-base * 1.4;
$h2-font-size: $font-size-base * 1.05;
$h3-font-size: $font-size-base * 1;
$h4-font-size: $font-size-base * 0.9;
$h5-font-size: $font-size-base * 0.8;
$h6-font-size: $font-size-base * 0.7;

$theme-colors: (
        "primary": $primary-green,
        "secondary": $secondary-gray,
        "white": $white,
        "dark": $dark,
        "darker": $text-color,
        "dark-light": $secondary-gray,
        "light": $light,
        "lightest": $lightest,
        "block-title-color": $block-title-color,
        "info": $info,
        "success": $success,
        "warning":$warning,
        "error":$error
);

$primary: $primary-green;

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4,
        7: $spacer * 5,
);

$enable-cssgrid: true;

// Buttons
$btn-border-radius: 2px;
$btn-font-size: $font-size-base * 0.8;
$btn-font-weight: $font-weight-medium;

// Carousel
$carousel-control-color: $primary-green;
$carousel-control-opacity: 1;
$carousel-indicator-opacity: 0.3;
$carousel-indicator-active-bg: $text-color;

$carousel-control-icon-width: 1.8rem;
$carousel-control-icon-height: 1.8rem;

// Dropdown
$dropdown-border-radius: 4px;
$dropdown-border-color: transparent;
$dropdown-link-hover-bg: #E7EFED;
$dropdown-link-hover-color: $primary-green;
$dropdown-min-width: 20rem;
@import 'mixins';

:root {
  @each $breakpoint, $value in $grid-breakpoints {
    --#{$breakpoint}-breakpoint: #{$value};
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $light;
  margin-right: auto;
  margin-left: auto;
  max-width: $body-max-width;

  &.no-scroll {
    overflow: hidden;
  }
}

@media (min-width: 576px) {
  .container-fluid {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1680px;
  }

  .container {
    max-width: 1400px;
  }
}

.no-last-child-margin-bottom {
  * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

a {
  @include font-size($font-size-base * 0.9);
  text-decoration: none;

  &.btn-link {
    text-decoration: none;
  }

  &.no-link-style {
    @include link-unset;
  }

  &.btn-outline-secondary {
    &:hover {
      color: $white;
      background-color: $primary-green;
      border-color: $primary-green;
    }
  }
}

.bg-primary {
  a {
    &.btn-light {
      border: 2px solid $white !important;

      &:hover {
        color: $primary-green !important;
      }
    }

    &.btn-white {
      &:hover {
        color: $primary-green;
        background-color: $light;
        border-color: $light;
      }
    }
  }
}

.green-border-bottom {
  border-bottom: 6px solid $primary-green;
}

.news-content {
  .green-border-bottom {
    border-bottom: none !important;
  }
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: $narrow-letter-spacing;
  color: $text-color;
}

p, ul, li {
  color: $text-color;
  @include font-size($font-size-base * 0.9);
}

.block-title {
  color: $secondary-gray;
  @include font-size($font-size-base * 1.3);
  font-weight: bold;
  letter-spacing: $wide-letter-spacing;

    @include media-breakpoint-down(sm) {
        @include font-size($font-size-base * 1);
        letter-spacing: $narrow-letter-spacing;
    }
}

.btn-primary, .btn-primary:hover {
  color: $white;
}

.btn-outline-secondary {
  color: $black;
  font-weight: 500;

  &:hover {
    color: $white;
  }
}

.breadcrumbs {
  z-index: $zindex-sticky;
}

.carousel-item {
  img {
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}

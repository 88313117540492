.block-call-to-action {
  .call-to-action-title {
    h2 {
      letter-spacing: $wide-letter-spacing;
      font-weight: $font-weight-light;
      @include rfs($font-size-base * 1.4, line-height);
    }
  }
  .call-to-action-text {
    font-family: $headings-font-family;
  }
}

$primary-green: #13A77B;
$secondary-gray: #B7B7B7;

$text-color: #747474;

$navbar-link-color: #131313;
$navbar-link-hover-color: $primary-green;
$block-title-color: #AFAFAF;
$footer-text-color: #767676;
$footer-background-color: #EDEDED;
$footer-border-color: #B4B4B4;

$white: #FFFFFF;
$black: #000000;
$dark: #1D1D1D;
$darker: #707070;
$lighter: #E0E4EF;
$light: #F8F8F8;
$lightest: #EDEDED;
$info: #6180BC;
$success: #488B60;
$warning: #F0BC47;
$error: #D24345;

@mixin link-unset(
  $color: unset,
  $border-bottom: unset,
  $background-color: unset,
  $padding-bottom: unset,
  $hover-color: unset,
  $text-decoration: unset,
  $content-before: unset,
  $content-after: unset,
  $hover-border-bottom: unset,
  $hover-background-color: unset,
  $hover-padding-bottom: unset,
  $hover-transition: unset,
  $hover-text-decoration: unset,
) {
  color: $color;
  border-bottom: $border-bottom;
  background-color: $background-color;
  padding-bottom: $padding-bottom;
  text-decoration: $text-decoration;

  &:hover {
    color: $hover-color;
    border-bottom: $hover-border-bottom;
    background-color: $hover-background-color;
    padding-bottom: $hover-padding-bottom;
    text-decoration: $hover-text-decoration;
  }

  &::before {
    content: $content-before;
  }

  &::after {
    content: $content-after;
  }
}

.card-element {
  border-left: 6px solid $primary-green;
  overflow: hidden;

  .card-element-title {
    @include font-size($font-size-base * 0.8);
    line-height: 1.6rem;
    letter-spacing: $narrow-letter-spacing;
    min-height: 64px;
    word-break: break-word;
    color: $footer-text-color;
  }

  .card-element-text {
    p {
      @include font-size($font-size-base * 0.8);
      color: $footer-text-color;
    }
  }
}

.block-memberships {
  .membership {
    .logo-bg-wrapper {
      height: 85px;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }
    }

    .membership-name {
      @include font-size($font-size-base * 0.7);
      font-family: $headings-font-family;
      letter-spacing: $narrow-letter-spacing;
    }
  }
}
